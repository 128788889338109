import React, { useEffect, useState } from "react"
import BugSupport from "../assets/img/bugsupport.inline.svg"
import Maintenance from "../assets/img/maintenance.inline.svg"
import DevelopmentStages from "../components/PPC/WebDesignPpc/DevelopmentStages"
import Faq from "../components/PPC/WebDesignPpc/Faq"
import FieldTechnologies from "../components/PPC/WebDesignPpc/FieldTechnologies"
import Modal from "../components/PPC/Modal"
import ModalForm from "../components/PPC/ModalForm"
import Testimonials from "../components/PPC/WebDesignPpc/TestimonialSlider"
import WebsiteArticles from "../components/PPC/WebDesignPpc/WebsiteArticles"
import Seo from "../components/SEO"
import Portfolio from "../components/PPC/WebDesignPpc/Portfolio"
import PgHdr from "../components/PPC/WebDesignPpc/PgHdr"
import PPCFooter from "../components/PPC/Footer"
import VideoSection from "../components/PPC/WebDesignPpc/VideoSection"
import { useContextValues } from "../context/NavBarContext"

const WebServiceCompany = () => {
  const { changeCurrentPath } = useContextValues()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => changeCurrentPath(location.pathname), [])
  return (
    <>
      <section className="bg-black py-14 max-[1440px]:pb-[5rem] max-[1440px]:pt-[3rem]">
        <PgHdr currency="INR" />
      </section>
      <WebsiteArticles setShowModal={setShowModal} />
      <VideoSection setShowModal={setShowModal} />
      <section className="lg:pt-14 md:pt-10 pt-8 lg:pb-14 md:pb-10 pb-8">
        <Testimonials />
        <div className="flex justify-center flex-wrap mt-2 md:px-0 px-[1rem]">
          <button
            type="button"
            onClick={() => setShowModal(prev => !prev)}
            className="inline-white text-white bg-primary text-base rounded-3xl px-[2rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500"
          >
            Get a website built by a top-rated company
          </button>
        </div>
      </section>
      <DevelopmentStages />
      <Portfolio setShowModal={setShowModal} />
      <FieldTechnologies setShowModal={setShowModal} />

      <section className="lg:pt-14 md:pt-10 pt-8 lg:pb-14 md:pb-10 pb-8">
        <div className="container">
          <div className="text-center md:w-10/12 w-full mx-auto mb-7">
            <h3 className="md:text-[2rem] text-[1.625rem] md:leading-[2.75rem] leading-[2.4rem] font-blancotrialbold">
              You can count on us even after the launch
              <span className="bg-orangecol w-2 h-2 rounded-full inline-block ml-1"></span>
            </h3>
          </div>
          <ul className="flex justify-between flex-wrap">
            <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(41.6%-0.938rem)] w-full max-w-full md:py-12 md:px-[3.438rem] py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
              <BugSupport />
              <h4 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                Free bug support for 2 months
                <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1"></span>
              </h4>
              <p className="text-grey text-base leading-7">
                We provide free support for 2 months after launch. If there are
                any issues, errors, or bugs with your website, we'll fix them
                for free.
              </p>
            </li>
            <li className="bg-white-300 md:mb-[1.875rem] mb-5 md:max-w-[calc(58.33%-0.938rem)] w-full max-w-full md:py-12 md:px-[3.438rem] py-10 px-7 flex flex-col text-center rounded-[1.25rem] justify-center items-center">
              <Maintenance />
              <h4 className="md:text-2xl text-xl pb-1 font-blancotrialbold leading-9 text-center pt-4">
                Maintenance contract
                <span className="bg-orangecol w-1 h-1 rounded-full inline-block ml-1"></span>
              </h4>
              <p className="text-grey text-base leading-7">
                Need support to maintain your website so that you can focus on
                your business? We've got that covered too. You can get your
                website maintenance contract and we'll handle all your website
                maintenance needs.
              </p>
            </li>
          </ul>
        </div>
        <div className="flex justify-center flex-wrap">
          <button
            type="button"
            onClick={() => {
              setShowModal(prev => !prev)
            }}
            className="inline-white text-white bg-primary text-base rounded-3xl px-[2rem] py-[0.7rem] font-gorditamedium hover:bg-orange-500 hover:text-white transition-all duration-500"
          >
            Let’s talk to build your modern website
          </button>
        </div>
      </section>
      <Faq setShowModal={setShowModal} />
      <Modal showModal={showModal}>
        <ModalForm currency="INR" toggleModal={setShowModal} />
      </Modal>
      <PPCFooter currency="INR" />
    </>
  )
}
export const Head = () => <Seo title="Web Design Company" noIndex />

export default WebServiceCompany
